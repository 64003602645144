import styles from "./styles.module.css";
import { ChatBar, ModelChat, ModelInfo } from "@widgets/chat";
import { AsideStructure } from "@widgets/aside_with_content";
import { IUserState, useUserStore } from "@entities/user";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";
import { useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";

const ChatPage = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const isAuth = useUserStore((state: IUserState) => state.user.id);
  const [steps, setSteps] = useState<string>("");
  const plan = useUserStore((state: IUserState) => state.user.plan);
  const { model }: any = useModelStore(
    (state: IModelState) => state.modelActive
  );

  var width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const isDesktop = width >= 1100;

  if (!isAuth && !id) {
    setTimeout(() => navigate(`${ROUTES.REGISTER}?source=Side Menu Chat`), 0);

    return <></>;
  }

  const DesktopContent = (
    <div className={styles.chat_container}>
      <AsideStructure>
        <div className={styles.chat_content}>
          <div className={styles.chat_bar_wrapper}>
            <ChatBar isDesktop={isDesktop} setSteps={setSteps} />
          </div>
          <div className={styles.chat_content_wrapper}>
            <ModelChat setSteps={setSteps} />
          </div>
          <div className={styles.chat_info_wrapper}>
            <ModelInfo />
          </div>
        </div>
      </AsideStructure>
    </div>
  );

  if (isDesktop) {
    return DesktopContent;
  }

  if (id && steps === "modelInfo") {
    amplitude.track(`Character Details Viewed (Mobile)`, {
      characterId: model.id,
      userType: plan || "free",
    });

    return (
      <AsideStructure>
        <div className={`${styles.chat__adaptive} ${styles.chat_hidden_block}`}>
          <ChatBar isDesktop={isDesktop} setSteps={setSteps} />
        </div>
        <div className={styles.chat__adaptive}>
          <ModelInfo setSteps={(value) => setSteps(value)} />
        </div>
      </AsideStructure>
    );
  }

  if (id || steps === "modelChat") {
    return (
      <AsideStructure>
        <div className={`${styles.chat__adaptive} ${styles.chat_hidden_block}`}>
          <ChatBar isDesktop={isDesktop} setSteps={setSteps} />
        </div>
        <div className={styles.chat__adaptive}>
          <ModelChat setSteps={(value) => setSteps(value)} />
        </div>
      </AsideStructure>
    );
  }

  return (
    <AsideStructure>
      <div className={styles.chat__adaptive}>
        <ChatBar isDesktop={isDesktop} setSteps={(value) => setSteps(value)} />
      </div>
    </AsideStructure>
  );
};

export default ChatPage;
