import styles from "./styles_relationship.module.css";

import Stranger from "@assets/constructor/ethnicity/rel_stranger.png";

interface IProps {
  occupations: string[];
  occupationChoose: (data: string) => void;
}

interface IRelationship {
  title: string;
  value: string;
  icon?: string;
}

const RELATIONSHIPS: IRelationship[] = [
  {
    title: "Stranger",
    value: "Stranger",
    icon: Stranger,
  },
];

const ChooseRelationship = ({ occupations, occupationChoose }: IProps) => {
  return (
    <div>
      <h4 className={styles.relationship_title}> Choose Relationship</h4>
      <div className={styles.relationship_container}>
        {RELATIONSHIPS.map((item, index) => (
          <OccupationItem
            key={index}
            item={item}
            //ethnicity={ethnicity}
            //ethnicityChoose={ethnicityChoose}
          />
        ))}
      </div>
    </div>
  );
};

interface IEyeProps {
  item: IRelationship;
  //eye: string;
  //eyeChoose: (data: string) => void;
}

const OccupationItem = ({ item }: IEyeProps) => {
  return (
    <div
      className={`${styles.eye_item} ${
        "" === item.value ? styles.eye_active : ""
      }`}
      onClick={() => {}}
    >
      <div className={styles.eye_wrapper}>
        <img src={item.icon} alt={item.title} />
      </div>
      <div className={styles.eye_color}>{item.title}</div>
    </div>
  );
};

export default ChooseRelationship;
