import styles from "./styles.module.css";

import { ROUTES } from "@shared/interfaces";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Info from "@assets/info_blue.svg";
import Apple from "@assets/apple.svg";
import Google from "@assets/google.svg";
import Check from "@assets/check_black.svg";
import Visa from "@assets/visa.svg";
import Master from "@assets/master.svg";
import Arrow from "@assets/arrow_long.svg";
import FooterLogo from "@assets/footer_payment.svg";
import { ButtonFactory } from "@shared/ui/Button";
import { InputItem } from "@shared/ui/Inputs";
import { MaskedInput, createDefaultMaskGenerator } from "react-hook-mask";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import { IUserState, useUserStore } from "@entities/user";
import { Spinner } from "@shared/ui/Spinner";
import * as amplitude from "@amplitude/analytics-browser";
import { v4 as uuidv4 } from "uuid";

const mask = "9999 9999 9999 9999";
const maskGenerator = createDefaultMaskGenerator(mask);

const maskExpiry = "99/99";
const maskGeneratorExpire = createDefaultMaskGenerator(maskExpiry);

const ICardState = { name: "", expire: "", number: "", cvv: "" };

const PaymentPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cardValue, setCardValue] = useState(ICardState);
  const [isSpinnerShow, setSpinnerShow] = useState<boolean>(true);
  const [isRedirect, setRedirect] = useState<boolean>(false);
  const navigate = useNavigate();

  const { setSubscriptionSuccessful, setAddTokensSuccessful } = useModelStore(
    (state: IModelState) => state
  );

  const { setPayment, sendAdsInfo } = useUserStore(
    (state: IUserState) => state
  );

  document.body.className = "payment";

  useEffect(() => {
    if (duration) {
      amplitude.track(`Payment Page Shown`, {
        planType: duration,
      });
    }

    setTimeout(() => {
      setSpinnerShow(false);
    }, 3000);
  }, []);

  if (isRedirect) {
    navigate(ROUTES.HOME);
  }

  const activeSearchParams = Object.fromEntries(searchParams.entries());
  let payment_type = activeSearchParams?.payment_type;

  let price = activeSearchParams?.price;
  let duration = activeSearchParams?.duration;

  let amount = activeSearchParams?.amount;

  const [activeFormError, setActiveFormError] =
    useState<Record<string, string>>();

  const handlerBeforeSubmitForm = async () => {
    const name = cardValue.name;
    const expire = cardValue.expire;
    const number = cardValue.number;
    const cvv = cardValue.cvv;

    if (!name || name.length > 64 || name.split(" ").length > 2) {
      return setActiveFormError({ name: "The Cardholder value is invalid" });
    }

    if (!expire || checkExpire(expire)) {
      return setActiveFormError({
        name: "The Expiration date value is invalid",
      });
    }

    if (!number) {
      return setActiveFormError({ name: "The card number value is invalid" });
    }

    if (!cvv || cvv.length > 3) {
      return setActiveFormError({ name: "The CVC is invalid" });
    }

    setActiveFormError({});

    const subid = localStorage.getItem("subid");

    if (payment_type === "subscribe") {
      await setPayment(null, "Card");

      await sendAdsInfo({
        status: "sale",
        currency: "usd",
        tid: uuidv4(),
        payout: price,
        subid,
      });

      amplitude.track(`Payment Successful`, {
        planType: duration,
        paymentMethod: "Card",
      });

      setSubscriptionSuccessful(true);
    } else {
      await setPayment(amount, "Card");

      await sendAdsInfo({
        status: "rebill",
        currency: "usd",
        payout: price,
        tid: uuidv4(),
        subid,
      });

      amplitude.track(`Token Purchase Successful`, {
        tokenAmount: amount,
      });
      setAddTokensSuccessful(true);
    }

    setRedirect(true);
  };

  const onclickAppleGoogleClick = async (method: string) => {
    const subid = localStorage.getItem("subid");

    if (payment_type === "subscribe") {
      await setPayment(null, method);

      await sendAdsInfo({
        status: "sale",
        currency: "usd",
        tid: uuidv4(),
        payout: price,
        subid,
      });

      setSubscriptionSuccessful(true);
    } else {
      await setPayment(amount, method);
      await sendAdsInfo({
        status: "rebill",
        currency: "usd",
        payout: price,
        tid: uuidv4(),
        subid,
      });

      setAddTokensSuccessful(true);
    }

    amplitude.track(`Token Purchase Successful`, {
      tokenAmount: amount,
      paymentMethod: method,
    });

    setSpinnerShow(true);

    const success = () => {
      setSpinnerShow(false);
      setRedirect(true);
    };

    setTimeout(() => success(), 3000);
  };

  const checkExpire = (expire: string) => {
    const month = expire.slice(0, 2);
    const year = "20" + expire.slice(2, 4);

    const currentYear = new Date().getFullYear();
    const allMonth = 12;

    if (+year >= currentYear && +month <= allMonth && +month > 0) {
      return false;
    }

    return true;
  };

  const onReturn = () => {
    amplitude.track(`Returned to Subscription Page`, {
      reason: "Payment Canceled",
    });
    navigate(-1);
  };

  const setCurrentValue = (e: any, property: string) => {
    if (property === "cvv") {
      const regex = /^\d+$/;

      const value = e?.target?.value || "0";

      if (!regex.test(value) || value.length >= 4) {
        return;
      }
    }

    if (e.target) {
      return setCardValue({ ...cardValue, [property]: e?.target?.value });
    }

    setCardValue({ ...cardValue, [property]: e });
  };

  const renderSubtitle = () => {
    if (payment_type === "subscribe") {
      return `Premium Plan for ${duration}`;
    }

    return `${amount} tokens`;
  };

  if (!price) {
    price = "5.99";
  }

  if (isSpinnerShow) {
    return (
      <div className={styles.spinner_position}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.payment__container}>
      <div className={styles.alert__container}>
        <img src={Info} alt="user_profile" />
        <h5 className={styles.alert__text}>
          All communications and notifications regarding applied changes will be
          via email.
        </h5>
      </div>

      <div className={styles.payment__order}>
        <div className={styles.payment_order}>
          <h4>Order summary</h4>
        </div>
        <div className={styles.payment_order_content}>
          <h5>GetFlirt AI</h5>
          <span>{renderSubtitle()}</span>
        </div>

        <div className={styles.payment_order_total}>
          <h5>Total</h5>
          <h5>
            <span>$</span>
            {price}
          </h5>
        </div>
      </div>

      <div className={styles.payment_method}>
        <h5>Payment method</h5>

        <div className={styles.payment_btn}>
          <ButtonFactory
            variant="primary"
            type="button"
            label=""
            onClick={() => onclickAppleGoogleClick("Apple Pay")}
            children={
              <div className={styles.pay_button_wrapper}>
                <img src={Apple} alt="apple" />
                <h5>Pay</h5>
              </div>
            }
          />
          <ButtonFactory
            variant="primary"
            label="Pay"
            type="button"
            onClick={() => onclickAppleGoogleClick("Google Play")}
            children={
              <div className={styles.pay_button_wrapper}>
                <img src={Google} alt="google" />
                <h5>Pay</h5>
              </div>
            }
          />
        </div>
        <div className={styles.payment_divider_container}>
          <div className={styles.payment_divider}></div>
          <h5>Or</h5>
          <div className={styles.payment_divider}></div>
        </div>
      </div>

      <div className={styles.cards}>
        <div className={styles.card_title}>
          <div className={styles.title_left}>
            <img src={Check} alt="check" />
            <h5>Pay with Credit Card</h5>
          </div>
          <div className={styles.card__items}>
            <img src={Visa} alt="visa" />
            <img src={Master} alt="check" />
          </div>
        </div>
        <div className={styles.card_info}>
          <div className={styles.card_info_left}>
            <h5>Name on card</h5>
            <InputItem
              type="text"
              name="card_text"
              value={cardValue.name.toUpperCase()}
              placeholder="Cardholder name and surname"
              onChange={(e: any) => setCurrentValue(e, "name")}
            />
          </div>
          <div className={styles.carg_info_right}>
            <h5>Expiry</h5>
            <MaskedInput
              value={cardValue.expire}
              maskGenerator={maskGeneratorExpire}
              onChange={(e: any) => setCurrentValue(e, "expire")}
              placeholder={"MM/YY"}
            />
          </div>
        </div>

        <div className={styles.card_info}>
          <div
            className={`${styles.card_info_left} ${styles.card_info_left_align}`}
          >
            <h5>Card number</h5>
            <img src={Master} alt="check" className={styles.master} />
            <MaskedInput
              value={cardValue.number}
              maskGenerator={maskGenerator}
              onChange={(e: any) => setCurrentValue(e, "number")}
              placeholder={"Card number"}
            />
          </div>
          <div className={styles.carg_info_right}>
            <h5>CVV</h5>
            <InputItem
              type="password"
              name="card_text"
              value={cardValue.cvv}
              placeholder="CVC"
              onChange={(e: any) => setCurrentValue(e, "cvv")}
            />
          </div>

          {activeFormError && (
            <span className={styles.form_error}>{activeFormError.name}</span>
          )}
        </div>
      </div>

      <div className={styles.confirm}>
        <ButtonFactory
          variant="primary"
          label="Confirm"
          type="button"
          onClick={handlerBeforeSubmitForm}
        />
      </div>

      <h5 className={styles.return} onClick={onReturn}>
        <img src={Arrow} alt="arrow" />
        Return
      </h5>

      <div className={styles.payment_divider}></div>

      <div className={styles.payment__footer}>
        <h5>GetFlirt AI Ltd</h5>

        <div className={styles.footer_content}>
          <h5>Powered by</h5>
          <img src={FooterLogo} alt="footer_logo" />
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
