import styles from "./styles.module.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RouterBuilder } from "./config/routes";
import { IUserState, useUserStore } from "@entities/user";
import { Portal } from "@widgets/portal";
import { Interested } from "@widgets/interested";
import { UserAuthContextProvider } from "@shared/model";
import { ErrorBoundary } from "react-error-boundary";
import { IServerMessage, ROUTES } from "@shared/interfaces";
import { SkeletonTheme } from "react-loading-skeleton";
import React, { useEffect, useState } from "react";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import "react-loading-skeleton/dist/skeleton.css";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

import { Cookie } from "@widgets/cookie";
import { Restrictions } from "@widgets/restrictions";

const PING_PONG_INTERVAL = 30000;
const INTERVAL = 1500;

const App: React.FC = () => {
  const user = useUserStore((state: IUserState) => state.user);
  const isAuth = user.id && user.id !== "initialising";
  const [isCookieShow, setCookieShow] = useState<boolean>(false);
  const [isOver18Show, setIsOver18Show] = useState<boolean>(false);

  const { model }: any = useModelStore(
    (state: IModelState) => state.modelActive
  );

  const { saveAdToken }: any = useUserStore((state: IUserState) => state);

  const {
    setMessage,
    setIsTyping,
    setDisableBtn,
    setLastMessage,
    setGenerationProgress,
  } = useModelStore((state: IModelState) => state);

  if (model?.id) {
    (window as any).model = model;
  }

  useEffect(() => {
    const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;
    amplitude.init(AMPLITUDE_KEY || "", { autocapture: true });
    // Create and Install Session Replay Plugin
    const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 0.2 });
    amplitude.add(sessionReplayTracking);

    if (
      !localStorage.getItem("isCookieShow") &&
      localStorage.getItem("IamOver18")
    ) {
      setCookieShow(true);
    }

    if (!localStorage.getItem("IamOver18")) {
      setIsOver18Show(true);
    }

    document.addEventListener("visibilitychange", function () {
      if (
        document.visibilityState === "visible" &&
        (window as any)?.socket?.readyState !== WebSocket.OPEN
      ) {
        console.log("Tab is active. Checking WebSocket connection...");
        socketConnection();
      }
    });
  }, []);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    const identifyEvent = new amplitude.Identify();
    identifyEvent.set("user_id", user?.email || "");
    amplitude.identify(identifyEvent);

    if (localStorage.getItem("subid")) {
      saveAdToken();
    }

    socketConnection();
  }, [isAuth]);

  let routes = new RouterBuilder().build();

  if (!isAuth) {
    routes = new RouterBuilder().addAuthRouters().build();
  }

  const router = createBrowserRouter(routes);

  const onRestrictionClose = () => {
    localStorage.setItem("IamOver18", "1");
    setIsOver18Show(false);
    setCookieShow(true);
  };

  const renderPreferences = () => {
    if (!localStorage.getItem("IamOver18")) {
      return;
    }

    const allowsRoutes: string[] = [
      ROUTES.GALLERY,
      ROUTES.HOME,
      ROUTES.CHAT,
      ROUTES.SUBSCRIBE,
    ];

    const isStartedChoosing = localStorage.getItem("isStartedChoosing");

    const pathName = window.location.pathname;
    const finedRouter = allowsRoutes.some((route) => route.includes(pathName));

    if (
      (!isStartedChoosing && finedRouter) ||
      (!isStartedChoosing && /^\/chat\/[0-9]$/.test(pathName) === true) ||
      (!isStartedChoosing && /^\/chat\/\$/.test(pathName) === true)
    ) {
      return (
        <Portal className="portal_home">
          <Interested />
        </Portal>
      );
    }
  };

  const renderRestriction = () => {
    const over18 = localStorage.getItem("IamOver18");

    if (over18) {
      return;
    }

    const allowsRoutes: string[] = [ROUTES.TERMS, ROUTES.PRIVACY_POLICY];

    const pathName = window.location.pathname;
    const finedRouter = allowsRoutes.some((route) => route === pathName);

    if (!finedRouter) {
      return (
        <Portal className="portal_home">
          <Restrictions onClose={onRestrictionClose} />
        </Portal>
      );
    }
  };

  const isOpen = (ws: any) => {
    return ws?.readyState === ws?.OPEN;
  };

  const socketConnection = () => {
    const { REACT_APP_WS_URL } = process.env;
    const token = localStorage.getItem("_accessToken");

    if (!isOpen((window as any).socket)) {
      clearInterval((window as any).interval);
      (window as any).socket.close();
    }

    (window as any).socket = new WebSocket(
      `${REACT_APP_WS_URL}?token=${token}`
    );

    (window as any).socket.onopen = () => {
      (window as any).interval = null;

      clearInterval((window as any).interval);
      console.log(`[WSS]: connected`);

      setPingPongConnection();
    };

    (window as any).socket.onmessage = (message: any) => {
      const gettingMessage = message.data;

      if (gettingMessage === "pong") {
        return;
      }

      const messageToJson = JSON.parse(gettingMessage);

      if (messageToJson.action === "add" || messageToJson.action === "update") {
        getMessages(messageToJson.data);
      }
    };

    (window as any).socket.onclose = (e: any) => {
      console.log("[WS]: socket is closed.", e.reason);

      (window as any).interval = setTimeout(() => {
        socketConnection();
      }, INTERVAL);
    };

    (window as any).socket.onerror = (e: any) => {
      console.log("[WS]: socket is closed.", e);

      (window as any).socket.close();
    };

    const setPingPongConnection = () => {
      console.log(`[WSS]: ping-pong connection`);
      //TODO clear interval
      clearInterval((window as any).intervalID);
      (window as any).intervalID = null;

      (window as any).intervalID = setInterval(() => {
        if (!isOpen((window as any).socket)) return;
        (window as any).socket.send("ping");
      }, PING_PONG_INTERVAL);
    };
  };

  const getMessages = (message: any) => {
    console.log("====>Callback", message);
    messagesGateWay(message);
  };

  const messagesGateWay = (message: IServerMessage) => {
    const chatId = +message.chat_id;

    setDisableBtn(true);
    if (message.type === "typing") {
      setIsTyping(true, message);
    }

    if (message.type === "start_generation") {
      setGenerationProgress(true);
    }

    if (message.type === "image" || message.type === "text") {
      setIsTyping(false, message);
      setMessage(message);
      setDisableBtn(false);

      amplitude.track(`Message Received from AI`, {
        characterId: (window as any).model.id,
        characterName: (window as any).model.name,
        aiResponseType: message.type,
      });
    }

    setLastMessage(chatId, message);
  };

  const onCookieClose = () => {
    localStorage.setItem("isCookieShow", "1");
    setCookieShow(false);
  };

  return (
    <>
      {isCookieShow && <Cookie onClose={onCookieClose} />}
      {renderRestriction()}
      {renderPreferences()}
      <RouterProvider router={router} />
    </>
  );
};

const AppWithProvider: React.FC = () => {
  return (
    <div className={styles.root__container}>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <UserAuthContextProvider>
          <SkeletonTheme
            baseColor="#2C2C33"
            highlightColor="#1E1E23"
            duration={1.2}
          >
            <App />
          </SkeletonTheme>
        </UserAuthContextProvider>
      </ErrorBoundary>
    </div>
  );
};

export default AppWithProvider;
