import styles from "./styles_hobby.module.css";
import { IIA } from "@shared/interfaces";

interface IProps {
  occupations: string[];
  occupationChoose: (data: string) => void;
}

interface IOccupation {
  title: string;
  value: string;
}

const OCCUPATION: IOccupation[] = [
  {
    title: "Massage Therapist",
    value: "Massage Therapist",
  },
  {
    title: "Dentist",
    value: "Dentist",
  },
  {
    title: "Nutritionist",
    value: "Nutritionist",
  },
  {
    title: "Fitness Coach",
    value: "Fitness Coach",
  },
  {
    title: "Artist",
    value: "Artist",
  },
  {
    title: "Pharmacist",
    value: "Pharmacist",
  },
  {
    title: "Hairdresser",
    value: "Hairdresser",
  },
  {
    title: "Makeup Artist",
    value: "Makeup Artist",
  },
  {
    title: "Gynecologist",
    value: "Gynecologist",
  },
  {
    title: "Writer",
    value: "Writer",
  },
  {
    title: "Secretary",
    value: "Secretary",
  },
  {
    title: "Librarian",
    value: "Librarian",
  },
  {
    title: "Social Worker",
    value: "Social Worker",
  },
  {
    title: "Fashion Designer",
    value: "Fashion Designer",
  },
  {
    title: "Model",
    value: "Model",
  },
  {
    title: "Police officer",
    value: "Police officer",
  },
  {
    title: "Nurse",
    value: "Nurse",
  },
  {
    title: "Yoga Instructor",
    value: "Yoga Instructor",
  },
  {
    title: "Waitress",
    value: "Waitress",
  },
  {
    title: "Cook",
    value: "Cook",
  },
  {
    title: "Unemployed",
    value: "Unemployed",
  },
  {
    title: "Dancer",
    value: "Dancer",
  },
  {
    title: "Teacher",
    value: "Teacher",
  },
  {
    title: "Student",
    value: "Student",
  },
  {
    title: "Life Coach",
    value: "Life Coach",
  },
  {
    title: "Astronaut",
    value: "Astronaut",
  },
  {
    title: "Kindergarten Teacher",
    value: "Kindergarten Teacher",
  },
  {
    title: "Soldier/Military Personnel",
    value: "Soldier/Military Personnel",
  },
];

const HOBBY: IOccupation[] = [
  {
    title: "Yoga",
    value: "Yoga",
  },
  {
    title: "Vlogging",
    value: "Vlogging",
  },
  {
    title: "Fitness",
    value: "Fitness",
  },
  {
    title: "Hiking",
    value: "Hiking",
  },
  {
    title: "Traveling",
    value: "Traveling",
  },
  {
    title: "Dancing",
    value: "Dancing",
  },
  {
    title: "Gaming",
    value: "Gaming",
  },
  {
    title: "Parties",
    value: "Parties",
  },
  {
    title: "Rock",
    value: "Rock",
  },
  {
    title: "Series",
    value: "Series",
  },
  {
    title: "Anime",
    value: "Anime",
  },
  {
    title: "Cosplay",
    value: "Cosplay",
  },
  {
    title: "Art",
    value: "Art",
  },
  {
    title: "Self-Development",
    value: "Self-Development",
  },
  {
    title: "Diy Crafting",
    value: "Diy Crafting",
  },
  {
    title: "Veganism",
    value: "Veganism",
  },
  {
    title: "Photography",
    value: "Photography",
  },
  {
    title: "Volunteering",
    value: "Volunteering",
  },
  {
    title: "Art",
    value: "Art",
  },
  {
    title: "Cars",
    value: "Cars",
  },
  {
    title: "Watching Netflix",
    value: "Watching Netflix",
  },
  {
    title: "Martial Arts",
    value: "Martial Arts",
  },
];

const ChooseHobby = ({ occupations, occupationChoose }: IProps) => {
  return (
    <div>
      <h4 className={styles.occupation_title}> Choose Occupation</h4>
      <div className={styles.hobby_container}>
        {OCCUPATION.map((item, index) => (
          <OccupationItem
            key={index}
            item={item}
            //ethnicity={ethnicity}
            //ethnicityChoose={ethnicityChoose}
          />
        ))}
      </div>

      <h4 className={styles.hobby_title}> Choose Hobbies</h4>
      <h6 className={styles.hobby_subtitle}> You can choose up to 3</h6>

      <div className={styles.hobby_container}>
        {HOBBY.map((item, index) => (
          <OccupationItem
            key={index}
            item={item}
            //ethnicity={ethnicity}
            //ethnicityChoose={ethnicityChoose}
          />
        ))}
      </div>
    </div>
  );
};

interface IEyeProps {
  item: IOccupation;
  //eye: string;
  //eyeChoose: (data: string) => void;
}

const OccupationItem = ({ item }: IEyeProps) => {
  return (
    <div
      className={`${styles.hobby_item} ${
        "" === item.value ? styles.hobby_active : ""
      }`}
      onClick={() => {}}
    >
      <h6 className={styles.hobby_text}>{item.title}</h6>
    </div>
  );
};

export default ChooseHobby;
