import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import { DeletePopup, RestartPopup, Typing } from "@widgets/chat";
import { Loader } from "@features/loader";
import MessageItemFactory from "@features/message/MessageItem.fabric";
import HowToUsePopup from "../howToUsePopup/HowToUsePopup";
import { IChatItem, IMessage, ROUTES } from "@shared/interfaces";
import useForm from "@shared/model/hooks/useForm";
import { IFormMessageState } from "@widgets/auth/loginForm";
import onTypingFormValid from "@widgets/chat/ui/typing/typingValidationHook";
import { IUserState, useUserStore } from "@entities/user";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Portal } from "@widgets/portal";
import { BuyTokens, UpdatePlan } from "@widgets/subscription";
import { DropDownMenu } from "@shared/ui/DropDown";
import { getFormattedDate } from "@shared/libs";
import ModelChatSkeleton from "./ModelChatSkeleton";
import Arrow from "@assets/arrow_nav.svg";
import Dots from "@assets/dots.svg";
import * as amplitude from "@amplitude/analytics-browser";

const MENU_LIST: any[] = [
  {
    title: "Restart Chat",
    value: "restart_chat",
  },
  {
    title: "Delete",
    value: "delete_chat",
    activeKey: true,
  },
];

interface IProps {
  setSteps?: (step: string) => void;
}

const MESSAGES_COUNT_WITHOUT_SUB = 10;

const messageState: IFormMessageState = {
  message: "",
};

const ModelChat: React.FC<IProps> = ({ setSteps }: IProps) => {
  let { id } = useParams();
  const isAuth = useUserStore((state: IUserState) => state.user.id);
  const plan = useUserStore((state: IUserState) => state.user.plan);
  const tokens = useUserStore((state: IUserState) => state.user.tokens);
  const { setTokens } = useUserStore((state: IUserState) => state);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const chat = useRef<null | HTMLDivElement>(null);
  const top = useRef<null | HTMLDivElement>(null);
  const [isDropDownShow, setDropDownShow] = useState(false);
  const [showHowToUse, setHowToUse] = useState<boolean>(false);
  const [showFirstMessage, setFirstMessage] = useState<boolean>(true);
  const [showRestartPopup, setShowRestartPopup] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [isSuggestionVisible, setVisibleSuggestion] = useState<boolean>(true);

  document.body.className = isDropDownShow ? "chat_restart_active" : "";

  const { model }: any = useModelStore(
    (state: IModelState) => state.modelActive
  );

  const messages = useModelStore((state: IModelState) => state.messages);

  const modelsChats = useModelStore((state: IModelState) => state.modelsChats);

  const disabledBtn = useModelStore((state: IModelState) => state.disabledBtn);

  const isModelActiveLoading = useModelStore(
    (state: IModelState) => state.isModelActiveLoading
  );

  const isTyping = useModelStore((state: IModelState) => state.isTyping);

  const isGenerationInProgress = useModelStore(
    (state: IModelState) => state.isGenerationInProgress
  );

  const isUpdatePlanShow = useModelStore(
    (state: IModelState) => state.isUpdatePlanShow
  );

  const isBuyTokensShow = useModelStore(
    (state: IModelState) => state.isBuyTokensShow
  );

  const {
    deleteChat,
    fetchModel,
    sendMessage,
    setMessages,
    restartChat,
    setIsTyping,
    addChatFirst,
    setBuyTokens,
    setUpdatePlan,
    setDisableBtn,
    setLastMessage,
    getChatMessages,
    setGenerationProgress,
  } = useModelStore((state: IModelState) => state);

  let TIMER: any;
  var width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const isDesktop = width >= 1100;

  useLayoutEffect(
    () => () => {
      top?.current?.scrollIntoView(false);
    },
    []
  );

  useEffect(() => {
    return () => {
      isDesktop && setMessages([]);
      setUpdatePlan(false);
      setBuyTokens(false);
    };
  }, []);

  useEffect(() => {
    amplitude.track(`Character Selected`, {
      characterName: model.name,
      characterId: model.id,
    });

    amplitude.track(`Chat Shown`);

    onChangeInput({ target: { name: "message", value: "" } });

    if (messages && messages.length <= 1 && model?.id) {
      amplitude.track(`Message Greeting Received from AI`, {
        characterId: model.id,
        characterName: model.name,
      });
    }

    if (messages && messages.length === 0) {
      setDisableBtn(false);
    }
  }, [id]);

  useEffect(() => {
    chat?.current?.scrollIntoView(true);
    setTimeout(() => {
      chat?.current?.scrollIntoView(true);
    }, 1000);

    if (messages && messages.length === 1) {
      setFirstMessage(true);

      setTimeout(() => {
        setFirstMessage(false);
      }, 3000);
    }

    if (messages && messages.length < 2) {
      return setVisibleSuggestion(true);
    } else {
      setVisibleSuggestion(false);
    }

    return () => {
      setFirstMessage(false);
      clearTimeout(TIMER);
      setVisibleSuggestion(false);
    };
  }, [messages?.length, isTyping, isGenerationInProgress]);

  useEffect(() => {
    setIsTyping(false);
    setGenerationProgress(false);
  }, [messages?.length]);

  const { formData, onChangeInput, onSubmitForm, resetFormData } =
    useForm<IFormMessageState>(messageState, handlerBeforeSubmitForm);

  async function handlerBeforeSubmitForm() {
    if (!isAuth) {
      return navigate(`${ROUTES.REGISTER}?source=Chat`);
    }

    const formErrors = onTypingFormValid<IFormMessageState>(formData);
    const arrayOfErrors = Object.keys(formErrors);

    if (arrayOfErrors.length) {
      return;
    }

    if (disabledBtn) {
      return;
    }

    onSendMessage(formData.message);
    resetFormData();
  }

  const onSendMessage = async (text: string) => {
    if (!isAuth) {
      return navigate(`${ROUTES.REGISTER}?source=Chat`);
    }

    const activeChat = defineActiveChat();

    if (!activeChat?.id) {
      return;
    }

    const stringId = activeChat.id?.toString();

    amplitude.track(`Message Sent to AI`, {
      characterId: model.id,
      characterName: model.name,
      messageLength: text.length,
      mediaType: "Text",
      userType: plan || "free",
    });

    setDisableBtn(true);

    const result: any = await sendMessage(stringId, text);
    if (
      (result.type === "image_generation" ||
        result.type === "insufficient_tokens") &&
      result.error === "insufficient_balance"
    ) {
      amplitude.track(`Request Image Gen No Tokens`, {
        text: text,
      });
    }

    if (result.type === "image_generation" || result.type === "free_limit") {
      (window as any).declineReason = "Exceeded Free Limit";

      amplitude.track(`Free Message Limit Exceeded`, {
        characterId: model.id,
        characterName: model.name,
      });

      amplitude.track(`Premium Purchase Pop-Up Shown`, {
        reason: "Exceeded Free Limit",
      });

      setDisableBtn(false);

      return setUpdatePlan(true);
    }

    if (result.type === "insufficient_tokens") {
      (window as any).declineReason = "Image Generation";

      amplitude.track(`Tokens Depleted Tooltip Shown`, {
        characterId: model.id,
        characterName: model.name,
        tokensUsed: tokens && tokens - result.current_tokens,
        source: "Chat Screen",
      });

      setDisableBtn(false);
      return setBuyTokens(true);
    }

    if (result?.current_tokens || result?.current_tokens === 0) {
      setTokens(result?.current_tokens);

      amplitude.track(`Tokens Used for Image Generation`, {
        tokensUsed: 2,
        characterName: model.name,
        userType: plan,
      });
    }

    setLastMessage(activeChat?.id, {
      text: formData.message,
      send_at: getFormattedDate(),
    });

    return addChatFirst(activeChat.id);
  };

  const renderMessages = () => {
    if (!messages || !messages?.length) {
      return <></>;
    }

    if (showFirstMessage) {
      return (
        <MessageItemFactory
          type="model"
          variant="icon"
          icon={<Loader />}
          key={"message_loader"}
        />
      );
    }

    const activeChat = defineActiveChat();

    return messages?.map((message: IMessage, index) => {
      console.log(message);
      if (message.type === "typing") {
        return (
          <MessageItemFactory
            type="model"
            variant="icon"
            icon={<Loader />}
            key={"message_" + message.id}
          />
        );
      }

      if (message.type === "start_generation") {
        return (
          <MessageItemFactory
            icon={<Loader />}
            type="generate"
            variant="lottie"
            key={"message_" + message.id}
          />
        );
      }

      const isImg = message.images_urls;

      return (
        <MessageItemFactory
          key={"message_" + index}
          variant={isImg?.length ? "img" : "text"}
          text={message.text}
          type={message.from}
          id={message.id}
          chatId={activeChat?.id}
          img={isImg?.length ? isImg[0] : undefined}
          isBlured={message.is_blured}
        />
      );
    });
  };

  const addSuggestions = () => {
    amplitude.track(`Suggestions Clicked`, {
      characterName: model.name,
      characterId: model.id,
      dialogueId: id,
    });

    if (!isAuth) {
      localStorage.setItem("returnUrl", pathname);

      return navigate(`${ROUTES.REGISTER}?source=Chat Suggestion`);
    }

    clearTimeout(TIMER);
    TIMER = null;

    const messageText = `${formData.message} ${
      model?.user_first_msg_help || ""
    }`;

    onChangeInput({ target: { name: "message", value: messageText } });
    setVisibleSuggestion(false);
  };

  const onClick = async ({ type }: { type: string }) => {
    setDropDownShow(false);

    if (type === "restart_chat") {
      amplitude.track(`Chat Restarted`, {
        characterName: model.name,
        characterId: model.id,
        dialogueId: id,
        reason: "User Request",
        userType: plan || "free",
      });
      setShowRestartPopup(true);
    }

    if (type === "delete_chat") {
      amplitude.track(`Chat Deleted`, {
        characterName: model.name,
        characterId: model.id,
        dialogueId: id,
        reason: "User Request",
        userType: plan || "free",
      });
      setShowDeletePopup(true);
    }
  };

  const getStringId = () => {
    const activeChat = defineActiveChat();

    if (!activeChat?.id) {
      console.error("Active chat didn't defined");
      return;
    }

    const stringId = activeChat.id?.toString();

    return stringId;
  };

  const onRestartChat = async () => {
    setShowRestartPopup(false);

    const stringId = getStringId();

    if (!stringId) {
      return;
    }

    await restartChat(stringId);
  };

  const onDeleteChat = async () => {
    setShowDeletePopup(false);

    const stringId = getStringId();

    if (!stringId) {
      return;
    }

    if (modelsChats?.length === 1) {
      return;
    }

    const currentIndex = modelsChats?.findIndex(
      (chat) => chat.id === +stringId
    );

    if (currentIndex === undefined || !modelsChats) {
      return;
    }

    let nextChat = modelsChats[currentIndex + 1];

    if (!nextChat) {
      nextChat = modelsChats[0];
    }

    setDropDownShow(false);
    const modelId = nextChat.model.id + "";

    await deleteChat(stringId);

    await fetchModel(modelId);
    await getChatMessages(modelId);

    return navigate(`${ROUTES.CHAT}/${nextChat.model.id}`);
  };

  function defineActiveChat() {
    const activeChat =
      modelsChats &&
      modelsChats.find((chat: IChatItem) => chat.model.id === model.id);

    return activeChat;
  }

  const backgroundStyles = {
    backgroundImage: "url(" + model.photo + ")",
  };

  const goToChatBar = () => {
    navigate(ROUTES.CHAT);
    setSteps && setSteps("");
  };

  const renderChatHeader = () => {
    if (isModelActiveLoading) {
      return <ModelChatSkeleton />;
    }

    return (
      <div className={styles.model_chat_header}>
        <div className={styles.model_chat_header_info}>
          {isAuth && (
            <img
              src={Arrow}
              className={styles.model_chat_header_arrow}
              alt="user_profile_arrow"
              onClick={() => goToChatBar()}
            />
          )}
          <div
            style={backgroundStyles}
            className={styles.header_info_ava}
            onClick={() => setSteps && setSteps("modelInfo")}
          />
          <h4 onClick={() => setSteps && setSteps("modelInfo")}>
            {model.name}
          </h4>
        </div>
        {isAuth && (
          <DropDownMenu
            active=""
            isVisible={isDropDownShow}
            menuList={MENU_LIST}
            callBack={onClick}
            onVisibleChange={() => setDropDownShow(!isDropDownShow)}
          >
            <div
              className={styles.header_burger}
              onClick={() => setDropDownShow(!isDropDownShow)}
            >
              <img src={Dots} alt="burger" />
            </div>
          </DropDownMenu>
        )}
      </div>
    );
  };

  const onUpdatePlanClose = () => {
    amplitude.track(`Premium Purchase Pop-Up Closed`, {
      reason: (window as any)?.declineReason || "",
    });

    setUpdatePlan(false);
  };

  return (
    <div className={styles.model_chat_container}>
      <div ref={top} />
      {isUpdatePlanShow && (
        <Portal className="portal_home">
          <UpdatePlan onClose={onUpdatePlanClose} />
        </Portal>
      )}

      {isBuyTokensShow && (
        <Portal className="portal_home">
          <BuyTokens onClose={() => setBuyTokens(false)} />
        </Portal>
      )}

      {showDeletePopup && (
        <Portal className="portal_home">
          <DeletePopup
            name={model?.name}
            onDelete={onDeleteChat}
            onCancel={() => setShowDeletePopup(false)}
          />
        </Portal>
      )}

      {showRestartPopup && (
        <Portal className="portal_home">
          <RestartPopup
            name={model?.name}
            onRestart={onRestartChat}
            onCancel={() => setShowRestartPopup(false)}
          />
        </Portal>
      )}

      {showHowToUse && <HowToUsePopup onClose={() => setHowToUse(false)} />}

      {renderChatHeader()}
      <div className={styles.chat_body_container}>
        <>
          {renderMessages()}
          {isTyping && (
            <MessageItemFactory icon={<Loader />} variant="icon" type="model" />
          )}
          {isGenerationInProgress && (
            <MessageItemFactory
              icon={<Loader />}
              variant="lottie"
              type="generate"
            />
          )}
          <div ref={chat} />
        </>
      </div>

      {!isModelActiveLoading && (
        <div className={styles.model_chat_typing}>
          {isSuggestionVisible && (
            <div
              className={styles.model_chat_suggestion}
              onClick={addSuggestions}
            >
              <span>Suggestions</span>
              <h5>{model?.user_first_msg_help || ""}</h5>
            </div>
          )}
          <Typing
            formData={formData}
            onSubmitForm={onSubmitForm}
            onChangeInput={onChangeInput}
            showHowToUse={() => setHowToUse(true)}
          />
        </div>
      )}
    </div>
  );
};

export default ModelChat;
