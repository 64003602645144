import styles from "./styles_bodytype.module.css";

import Petite from "@assets/constructor/ethnicity/body_petite.png";
import Slim from "@assets/constructor/ethnicity/body_slim.png";
import Athletic from "@assets/constructor/ethnicity/body_athletic.png";
import Voluptuous from "@assets/constructor/ethnicity/body_voluptuous.png";
import Curvy from "@assets/constructor/ethnicity/body_curvy.png";

import BreastFlat from "@assets/constructor/ethnicity/breast_flat.png";
import BreastSmall from "@assets/constructor/ethnicity/breast_small.png";
import BreastMedium from "@assets/constructor/ethnicity/breast_medium.png";
import BreastLarge from "@assets/constructor/ethnicity/breast_large.png";
import BreastHuge from "@assets/constructor/ethnicity/breast_huge.png";

import ButtHuge from "@assets/constructor/ethnicity/butt_small.png";
import ButtMedium from "@assets/constructor/ethnicity/butt_medium.png";
import ButtLarge from "@assets/constructor/ethnicity/butt_large.png";
import ButtSkinny from "@assets/constructor/ethnicity/butt_skinny.png";
import ButtAthletic from "@assets/constructor/ethnicity/butt_athletic.png";

interface IProps {
  butt: string;
  bodyType: string;
  breastSize: string;
  bodyTypeChoose: (data: string) => void;
  buttTypeChoose: (data: string) => void;
  breastSizeChoose: (data: string) => void;
}

const ChooseBodyType = ({
  butt,
  bodyType,
  breastSize,
  bodyTypeChoose,
  buttTypeChoose,
  breastSizeChoose,
}: IProps) => {
  return (
    <div className="">
      <h4 className={styles.body_title}>Choose Body Type</h4>
      <div className={styles.body_container}>
        <div
          className={`${styles.body_item} ${
            bodyType === "petite" && styles.body_active
          }`}
          onClick={() => bodyTypeChoose("petite")}
        >
          <img src={Petite} alt="Petite" />
          <div className={styles.body_badge}>Petite</div>
        </div>

        <div
          className={`${styles.body_item} ${
            bodyType === "slim" && styles.body_active
          }`}
          onClick={() => bodyTypeChoose("slim")}
        >
          <img src={Slim} alt="Slim" />
          <div className={styles.body_badge}>Slim</div>
        </div>

        <div
          className={`${styles.body_item} ${
            bodyType === "athletic" && styles.body_active
          }`}
          onClick={() => bodyTypeChoose("athletic")}
        >
          <img src={Athletic} alt="Athletic" />
          <div className={styles.body_badge}>Athletic</div>
        </div>

        <div
          className={`${styles.body_item} ${
            bodyType === "voluptuous" && styles.body_active
          }`}
          onClick={() => bodyTypeChoose("voluptuous")}
        >
          <img src={Voluptuous} alt="Voluptuous" />
          <div className={styles.body_badge}>Voluptuous</div>
        </div>

        <div
          className={`${styles.body_item} ${
            bodyType === "curvy" && styles.body_active
          }`}
          onClick={() => bodyTypeChoose("curvy")}
        >
          <img src={Curvy} alt="Curvy" />
          <div className={styles.body_badge}>Curvy</div>
        </div>
      </div>

      <h4 className={styles.body_title}>Choose Breast Size</h4>
      <div className={styles.body_container}>
        <div
          className={`${styles.body_item} ${
            breastSize === "flat" && styles.body_active
          }`}
          onClick={() => breastSizeChoose("flat")}
        >
          <img src={BreastFlat} alt="Flat" />
          <div className={styles.body_badge}>Flat</div>
        </div>

        <div
          className={`${styles.body_item} ${
            breastSize === "small" && styles.body_active
          }`}
          onClick={() => breastSizeChoose("small")}
        >
          <img src={BreastSmall} alt="Small" />
          <div className={styles.body_badge}>Small</div>
        </div>

        <div
          className={`${styles.body_item} ${
            breastSize === "medium" && styles.body_active
          }`}
          onClick={() => breastSizeChoose("medium")}
        >
          <img src={BreastMedium} alt="medium" />
          <div className={styles.body_badge}>Medium</div>
        </div>

        <div
          className={`${styles.body_item} ${
            breastSize === "large" && styles.body_active
          }`}
          onClick={() => breastSizeChoose("large")}
        >
          <img src={BreastLarge} alt="Large" />
          <div className={styles.body_badge}>Large</div>
        </div>

        <div
          className={`${styles.body_item} ${
            breastSize === "huge" && styles.body_active
          }`}
          onClick={() => breastSizeChoose("huge")}
        >
          <img src={BreastHuge} alt="Huge" />
          <div className={styles.body_badge}>Huge</div>
        </div>
      </div>

      <h4 className={styles.body_title}>Choose The Butt Size</h4>
      <div className={styles.body_container}>
        <div
          className={`${styles.body_item} ${
            butt === "small" && styles.body_active
          }`}
          onClick={() => buttTypeChoose("small")}
        >
          <img src={ButtHuge} alt="Small" />
          <div className={styles.body_badge}>Small</div>
        </div>

        <div
          className={`${styles.body_item} ${
            butt === "medium" && styles.body_active
          }`}
          onClick={() => buttTypeChoose("medium")}
        >
          <img src={ButtMedium} alt="Medium" />
          <div className={styles.body_badge}>Medium</div>
        </div>

        <div
          className={`${styles.body_item} ${
            butt === "large" && styles.body_active
          }`}
          onClick={() => buttTypeChoose("large")}
        >
          <img src={ButtLarge} alt="Large" />
          <div className={styles.body_badge}>Large</div>
        </div>

        <div
          className={`${styles.body_item} ${
            butt === "skinny" && styles.body_active
          }`}
          onClick={() => buttTypeChoose("skinny")}
        >
          <img src={ButtSkinny} alt="Skinny" />
          <div className={styles.body_badge}>Skinny</div>
        </div>

        <div
          className={`${styles.body_item} ${
            butt === "athletic" && styles.body_active
          }`}
          onClick={() => buttTypeChoose("athletic")}
        >
          <img src={ButtAthletic} alt="Athletic" />
          <div className={styles.body_badge}>Athletic</div>
        </div>
      </div>
    </div>
  );
};

export default ChooseBodyType;
