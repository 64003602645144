import styles from "./styles_gender.module.css";
import Male from "@assets/constructor/male.png";
import FeMale from "@assets/constructor/female.png";

interface IProps {
  genderChoose: (value: string) => void;
  gender: string;
}

const ChooseGender = ({ gender, genderChoose }: IProps) => {
  return (
    <div>
      <h4 className={styles.gender_title}>Choose a gender</h4>
      <div className={styles.gender_container}>
        <div
          className={`${styles.gender_female} ${
            gender === "female" && styles.gender_active
          }`}
          onClick={() => genderChoose("female")}
        >
          <img src={FeMale} alt="female" />
          <div className={styles.gender_badge}>Female</div>
        </div>
        <div
          className={`${styles.gender_male} ${
            gender === "male" && styles.gender_active
          }`}
          onClick={() => genderChoose("male")}
        >
          <img src={Male} alt="male" />
          <div className={styles.gender_badge}>Male</div>
        </div>
      </div>
    </div>
  );
};

export default ChooseGender;
