import styles from "./styles.module.css";
import { LinkFactory } from "@shared/ui/Link";
import { ROUTES } from "@shared/interfaces";
import CloseIcon from "@assets/close.svg";
import UpdatePlanImg from "@assets/update-plan.jpg";
import Token from "@assets/token.svg";
import { useEffect } from "react";

interface IProps {
  onClose: () => void;
}

const BuyTokens = ({ onClose }: IProps) => {
  return (
    <div className={styles.buy_tokens_base}>
      <div className={styles.buy_tokens_container}>
        <div className={styles.buy_tokens_bg}>
          <img src={UpdatePlanImg} alt="buy_tokens" />
        </div>
        <div className={styles.buy_tokens_content}>
          <img
            src={CloseIcon}
            className={styles.buy_close_button}
            alt="close"
            onClick={onClose}
          />

          <div className={styles.bg_token_img}>
            <img src={Token} alt="token" />
          </div>
          <h2>
            Oops! Looks like we're out of tokens{" "}
            {String.fromCodePoint(parseInt("0x1F605", 16))}
          </h2>
          <span className={styles.buy_tokens_suptitle}>
            Top Up Now, And I'll Get Back To Creating Amazing Images For You!
          </span>
          <LinkFactory
            label="Get Tokens"
            type="danger"
            path={`${ROUTES.SUBSCRIBE}?source=Tokens Depleted Tooltip Shown`}
          />
        </div>
      </div>
    </div>
  );
};

export default BuyTokens;
