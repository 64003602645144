import styles from "./styles_heir_styles.module.css";

import Straight from "@assets/constructor/ethnicity/straight.png";
import Braids from "@assets/constructor/ethnicity/braids.png";
import Bangs from "@assets/constructor/ethnicity/bangs.png";
import Curly from "@assets/constructor/ethnicity/curly.png";
import Bun from "@assets/constructor/ethnicity/bun.png";
import Short from "@assets/constructor/ethnicity/short.png";
import Long from "@assets/constructor/ethnicity/long.png";
import Ponytail from "@assets/constructor/ethnicity/ponytail.png";
import Pigtails from "@assets/constructor/ethnicity/pigtails.png";
import Afro from "@assets/constructor/ethnicity/afro_h.png";

interface IProps {
  hair: string;
  hairColor: string;
  hairChoose: (data: string) => void;
  hairColorChoose: (data: string) => void;
}

const ChooseHeirStyle = ({
  hair,
  hairColor,
  hairChoose,
  hairColorChoose,
}: IProps) => {
  return (
    <div className="">
      <h4 className={styles.hair_title}>Choose Hair Style</h4>
      <div className={styles.hair_container}>
        <div
          className={`${styles.hair_item} ${
            hair === "straight" && styles.hair_active
          }`}
          onClick={() => hairChoose("straight")}
        >
          <img src={Straight} alt="Straight" />
          <div className={styles.hair_badge}>Straight</div>
        </div>

        <div
          className={`${styles.hair_item} ${
            hair === "braids" && styles.hair_active
          }`}
          onClick={() => hairChoose("braids")}
        >
          <img src={Braids} alt="Braids" />
          <div className={styles.hair_badge}>Braids</div>
        </div>

        <div
          className={`${styles.hair_item} ${
            hair === "bangs" && styles.hair_active
          }`}
          onClick={() => hairChoose("bangs")}
        >
          <img src={Bangs} alt="Bangs" />
          <div className={styles.hair_badge}>Bangs</div>
        </div>

        <div
          className={`${styles.hair_item} ${
            hair === "curly" && styles.hair_active
          }`}
          onClick={() => hairChoose("curly")}
        >
          <img src={Curly} alt="Curly" />
          <div className={styles.hair_badge}>Curly</div>
        </div>

        <div
          className={`${styles.hair_item} ${
            hair === "bun" && styles.hair_active
          }`}
          onClick={() => hairChoose("bun")}
        >
          <img src={Bun} alt="Bun" />
          <div className={styles.hair_badge}>Bun</div>
        </div>

        <div
          className={`${styles.hair_item} ${
            hair === "short" && styles.hair_active
          }`}
          onClick={() => hairChoose("short")}
        >
          <img src={Short} alt="Short" />
          <div className={styles.hair_badge}>Short</div>
        </div>

        <div
          className={`${styles.hair_item} ${
            hair === "long" && styles.hair_active
          }`}
          onClick={() => hairChoose("long")}
        >
          <img src={Long} alt="Long" />
          <div className={styles.hair_badge}>Long</div>
        </div>

        <div
          className={`${styles.hair_item} ${
            hair === "ponytail" && styles.hair_active
          }`}
          onClick={() => hairChoose("ponytail")}
        >
          <img src={Ponytail} alt="Ponytail" />
          <div className={styles.hair_badge}>Ponytail</div>
        </div>

        <div
          className={`${styles.hair_item} ${
            hair === "pigtails" && styles.hair_active
          }`}
          onClick={() => hairChoose("pigtails")}
        >
          <img src={Pigtails} alt="Pigtails" />
          <div className={styles.hair_badge}>Pigtails</div>
        </div>

        <div
          className={`${styles.hair_item} ${
            hair === "afro" && styles.hair_active
          }`}
          onClick={() => hairChoose("afro")}
        >
          <img src={Afro} alt="Afro" />
          <div className={styles.hair_badge}>Afro</div>
        </div>
      </div>

      <h4 className={styles.hair_title}>Choose Hair Color</h4>
      <div className={styles.hair_color_container}>
        <div
          className={`${styles.hair_color_item} ${styles.blonde} ${
            hairColor === "blonde" ? styles.color_active : ""
          }`}
          onClick={() => hairColorChoose("blonde")}
        >
          Blonde
        </div>
        <div
          className={`${styles.hair_color_item} ${styles.brunette} ${
            hairColor === "brunette" ? styles.color_active : ""
          }`}
          onClick={() => hairColorChoose("brunette")}
        >
          Brunette
        </div>
        <div
          className={`${styles.hair_color_item} ${styles.black} ${
            hairColor === "black" ? styles.color_active : ""
          }`}
          onClick={() => hairColorChoose("black")}
        >
          Black
        </div>
        <div
          className={`${styles.hair_color_item} ${styles.pink} ${
            hairColor === "pink" ? styles.color_active : ""
          }`}
          onClick={() => hairColorChoose("pink")}
        >
          Pink
        </div>
        <div
          className={`${styles.hair_color_item} ${styles.redhead} ${
            hairColor === "redhead" ? styles.color_active : ""
          }`}
          onClick={() => hairColorChoose("redhead")}
        >
          Redhead
        </div>
      </div>
    </div>
  );
};

export default ChooseHeirStyle;
