import styles from "./styles_personality.module.css";
import Caregiver from "@assets/constructor/ethnicity/person_caregiver.png";
import Sage from "@assets/constructor/ethnicity/person_sage.png";
import Innocent from "@assets/constructor/ethnicity/person_innocent.png";
import Jester from "@assets/constructor/ethnicity/person_jester.png";
import Temptress from "@assets/constructor/ethnicity/person_temptress.png";
import Dominant from "@assets/constructor/ethnicity/person_dominant.png";
import Submissive from "@assets/constructor/ethnicity/person_submissive.png";
import Lover from "@assets/constructor/ethnicity/person_lover.png";
import Nympho from "@assets/constructor/ethnicity/person_lover.png";
import Mean from "@assets/constructor/ethnicity/person_mean.png";
import Confidant from "@assets/constructor/ethnicity/person_confidant.png";
import Experimenter from "@assets/constructor/ethnicity/person_experiment.png";

interface IPersonality {
  title: string;
  value: string;
  description: string;
  icon: string;
}

const PERSONALITIES: IPersonality[] = [
  {
    title: "Caregiver",
    value: "caregiver",
    description: "Nurturing, protective, and always there to offer comfort.",
    icon: Caregiver,
  },
  {
    title: "Sage",
    value: "sage",
    description: "Wise, reflective, and a source of guidance.",
    icon: Sage,
  },
  {
    title: "Innocent",
    value: "innocent",
    description: "Optimistic, naive, and sees world with wonder.",
    icon: Innocent,
  },
  {
    title: "Jester",
    value: "jester",
    description: "Playful, humorous, and always there to make you laugh.",
    icon: Jester,
  },
  {
    title: "Temptress",
    value: "temptress",
    description: "Flirtatious, playful, and always leaving you wanting more.",
    icon: Temptress,
  },
  {
    title: "Dominant",
    value: "dominant",
    description: "Assertive, controlling, and commanding.",
    icon: Dominant,
  },
  {
    title: "Submissive",
    value: "submissive",
    description: "Obedient, yielding, and happy to follow.",
    icon: Submissive,
  },
  {
    title: "Lover",
    value: "lover",
    description: "Romantic, affectionate, and cherishes deep emotional",
    icon: Lover,
  },
  {
    title: "Nympho",
    value: "nympho",
    description: "Insatiable, passionate, and constantly craving intimacy.",
    icon: Nympho,
  },
  {
    title: "Mean",
    value: "mean",
    description: "Cold, dismissive, and often sarcastic.",
    icon: Mean,
  },
  {
    title: "Confidant",
    value: "confidant",
    description: "Trustworthy, a good listener, and always can offer advice.",
    icon: Confidant,
  },
  {
    title: "Experimenter",
    value: "experimenter",
    description: "Curious, willing, and always eager to try new things.",
    icon: Experimenter,
  },
];

interface IProps {
  personalityChoose: (value: string) => void;
  personality: string;
}

const ChoosePersonality = ({ personality, personalityChoose }: IProps) => {
  return (
    <div>
      <h4 className={styles.personality_title}>Choose Personality</h4>
      <div className={styles.personality_container}>
        {PERSONALITIES.map((item: IPersonality, index: number) => (
          <PersonalityItem
            key={index}
            item={item}
            personality={personality}
            personalityChoose={personalityChoose}
          />
        ))}
      </div>
    </div>
  );
};

interface IPropsPersonality {
  item: IPersonality;
  personality: string;
  personalityChoose: (data: string) => void;
}
const PersonalityItem = ({
  item,
  personality,
  personalityChoose,
}: IPropsPersonality) => {
  return (
    <div
      className={`${styles.personality_item} ${
        item.value === personality ? styles.personality_active : ""
      }`}
      onClick={() => personalityChoose(item.value)}
    >
      <div className={styles.personality_item_wrapper}>
        <img src={item.icon} alt={item.title} />
      </div>
      <h5 className={styles.personality_item_title}>{item.title}</h5>
      <h6 className={styles.personality_item_description}>
        {item.description}
      </h6>
    </div>
  );
};

export default ChoosePersonality;
