import { IFBUser, IUserState } from "./user.types";
import { axiosInstance } from "@shared/utils/configs/axiosInstance";
import { create } from "zustand";
import * as amplitude from "@amplitude/analytics-browser";
import { url } from "@shared/libs";

export const useUserStore = create<IUserState>((set, get) => {
  return {
    user: {
      //initialising | IUser | null
      id: "initialising",
      email: null,
      first_name: "",
      last_name: "",
      gender_choice: "",
      plan: "",
      tokens: 0,
      free_msgs: 0,
    },
    setUser: async (userData: any) => {
      try {
        if (userData?.accessToken) {
          localStorage.setItem("_accessToken", userData.accessToken);
          const startedChoosing = localStorage.getItem("isStartedChoosing");

          const { data } = await axiosInstance.get<IFBUser>("/api/user", {
            headers: {
              Authorization: userData.accessToken,
            },
          });

          if (startedChoosing) {
            await get().setUserChoice(startedChoosing);
            data.gender_choice = startedChoosing;
          }

          set({ user: { ...data } });
        } else {
          if (!userData) {
            set({ user: { id: null } });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    setUserChoice: async (choice: string) => {
      const axiosConfig = {
        headers: {
          Authorization: localStorage.getItem("_accessToken"),
        },
      };

      const body = {
        gender_choice: choice,
      };

      try {
        await axiosInstance.patch<IFBUser>("/api/user", body, axiosConfig);
        const currentUser = get().user;

        set({ user: { ...currentUser, gender_choice: choice } });
      } catch (e) {
        console.log(e);
      }
    },
    setPayment: async (price: string | any, method: string) => {
      try {
        const url = "/api/user/payment";
        const axiosConfig = {
          headers: {
            Authorization: localStorage.getItem("_accessToken"),
          },
        };

        let body = {};
        if (price) {
          body = { tokens: +price };
        } else {
          body = { plan: "premium" };
        }

        await axiosInstance.patch(url, body, axiosConfig);

        const { data } = await axiosInstance.get<IFBUser>(
          "/api/user",
          axiosConfig
        );

        set({ user: { ...data } });
      } catch (e) {
        if (price) {
          amplitude.track(`Token Purchase Failed`, {
            paymentMethod: method,
          });
        } else {
          amplitude.track(`Payment Failed`, {
            paymentMethod: method,
          });
        }
        console.log(e);
      }
    },
    setTokens: (tokens: number) => {
      const user = get().user;

      user.tokens = tokens;

      set({ user });
    },
    sendAdsInfo: async (query: any) => {
      try {
        const queryString = url.generateQueryString(query);
        await fetch(
          `https://getflirt-ai-trc.com/1410e72/postback?${queryString}`
        );
      } catch (e) {
        console.log(e);
      }
    },
    saveAdToken: async () => {
      const subid = localStorage.getItem("subid") || "";
      try {
        const { data } = await axiosInstance.patch(
          "/api/user/analytics",
          { new_id: subid },
          {
            headers: {
              Authorization: localStorage.getItem("_accessToken"),
            },
          }
        );

        localStorage.removeItem("subid");
        localStorage.setItem("subid_sended", subid);
      } catch (e) {
        console.log(e);
      }
    },
  };
});
