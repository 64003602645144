import { AsideStructure } from "@widgets/aside_with_content";
import styles from "./styles.module.css";
import { useState } from "react";
import Stepper from "./Stepper";
import ChooseGender from "./ChooseGender";
import { ButtonFactory } from "@shared/ui/Button";
import Arrow from "@assets/arrow_long_white.svg";
import ChooseEthnicity from "./ChooseEthnicity";
import ChooseHeirStyle from "./ChooseHairStyle";
import ChooseBodyType from "./ChooseBodyType";
import ChoosePersonality from "./ChoosePersonality";
import { IIA } from "@shared/interfaces";
import ChooseHobby from "./ChooseHobby";
import ChooseRelationship from "./ChooseRelationship";

const CreateAi = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [ai, setAi] = useState<IIA>({
    gender: "",
    ethnicity: "",
    age: "",
    eye: "",
    hairStyles: "",
    hairColor: "",
    bodyType: "",
    breastSize: "",
    butt: "",
    personality: "",
    occupations: [],
  });

  const setOccupation = (occupation: string) => {
    const aiOccupation = [...ai.occupations, occupation];

    setAi({ ...ai, occupations: aiOccupation });
  };

  const renderContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <ChooseGender
            gender={ai.gender}
            genderChoose={(gender: string) => setAi({ ...ai, gender })}
          />
        );
      case 1:
        return (
          <ChooseEthnicity
            age={ai.age}
            ethnicity={ai.ethnicity}
            eye={ai.eye}
            ethnicityChoose={(ethnicity: string) => setAi({ ...ai, ethnicity })}
            ageChoose={(age: string) => setAi({ ...ai, age })}
            eyeChoose={(eye: string) => setAi({ ...ai, eye })}
          />
        );

      case 2:
        return (
          <ChooseHeirStyle
            hair={ai.hairStyles}
            hairColor={ai.hairColor}
            hairChoose={(hairStyles: string) => setAi({ ...ai, hairStyles })}
            hairColorChoose={(hairColor: string) => setAi({ ...ai, hairColor })}
          />
        );

      case 3:
        return (
          <ChooseBodyType
            bodyType={ai.bodyType}
            breastSize={ai.breastSize}
            butt={ai.butt}
            buttTypeChoose={(butt: string) => setAi({ ...ai, butt })}
            bodyTypeChoose={(bodyType: string) => setAi({ ...ai, bodyType })}
            breastSizeChoose={(breastSize: string) =>
              setAi({ ...ai, breastSize })
            }
          />
        );

      case 4:
        return (
          <ChoosePersonality
            personality={ai.personality}
            personalityChoose={(personality: any) =>
              setAi({ ...ai, personality })
            }
          />
        );

      case 5:
        return (
          <ChooseHobby
            occupations={ai.occupations}
            occupationChoose={(occupation: string) => setOccupation(occupation)}
          />
        );

      case 6:
        return (
          <ChooseRelationship
            occupations={ai.occupations}
            occupationChoose={(occupation: string) => setOccupation(occupation)}
          />
        );
    }
  };

  return (
    <div className={styles.create_ai_content}>
      <AsideStructure>
        <h1 className={styles.create_ai_title}>
          Create <span>my AI</span>
        </h1>

        <Stepper step={currentStep} />

        <div className={styles.children_wrapper}>
          <div>{renderContent()}</div>
          <div
            className={`${styles.gender_nav} ${
              currentStep === 1 && styles.first
            }`}
          >
            {currentStep !== 1 && (
              <ButtonFactory
                variant="primary"
                label=""
                type="button"
                onClick={() => setCurrentStep(currentStep - 1)}
                children={
                  <div className={styles.prev_btn}>
                    <img src={Arrow} />
                    <span>Previous</span>
                  </div>
                }
              />
            )}
            <ButtonFactory
              variant="danger"
              label="Previous"
              type="button"
              onClick={() => setCurrentStep(currentStep + 1)}
              children={
                <div>
                  <span>Next</span>
                  <img src={Arrow} />
                </div>
              }
            />
          </div>
        </div>
      </AsideStructure>
    </div>
  );
};

export default CreateAi;
