import styles from "./styles.module.css";
import { useEffect } from "react";
import { useModelStore } from "@entities/model/model/model.store";
import { IModelState } from "@entities/model/model/model.types";
import { useLocation, useNavigate } from "react-router-dom";
import GalleryItemFactory from "@features/gallery/GalleryItem.fabric";
import { IModel } from "@shared/interfaces";
import { HeaderNav } from "@widgets/header_nav";
import { Portal } from "@widgets/portal";
import {
  AddTokensConfirmationPopup,
  SubscribeConfirmationPopup,
} from "@widgets/chat";
import * as amplitude from "@amplitude/analytics-browser";
import { IUserState, useUserStore } from "@entities/user";

const HomeGallery: React.FC = () => {
  let { search } = useLocation();
  const navigate = useNavigate();
  const {
    fetchModels,
    setModels,
    setSubscriptionSuccessful,
    setAddTokensSuccessful,
  } = useModelStore((state: IModelState) => state);
  const { saveAdToken }: any = useUserStore((state: IUserState) => state);

  const models = useModelStore((state: IModelState) => state.models);
  const isSubscriptionSuccessful = useModelStore(
    (state: IModelState) => state.isSubscriptionSuccessful
  );
  const isAddTokensSuccessful = useModelStore(
    (state: IModelState) => state.isAddTokensSuccessful
  );

  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get("type");
  const subId = urlParams.get("_subid");

  if (subId) {
    localStorage.setItem("subid", subId);
  }

  const choosedCharacterBefore = localStorage.getItem("isStartedChoosing");
  document.body.className = "";

  if (!type) {
    search = choosedCharacterBefore ? choosedCharacterBefore : "?type=female";
    //setTimeout(() => navigate(search), 300);
  }

  useEffect(() => {
    async function fetchAPI() {
      await fetchModels(search);
    }
    fetchAPI();

    return () => {
      setModels([]);
    };
  }, [search, fetchModels, setModels]);

  useEffect(() => {
    amplitude.track(`Explore AI Characters Page Shown`);
  }, []);

  if (!models?.length) {
    return <></>;
  }

  const modelsWithoutFullNAme = models.forEach((model) => {
    const firstName = model.name?.split(" ")[0];

    if (firstName) {
      model.name = firstName;
    }

    return model;
  });

  return (
    <div className={styles.home_gallery_container}>
      {isSubscriptionSuccessful && (
        <Portal className="portal_home">
          <SubscribeConfirmationPopup
            onCancel={() => setSubscriptionSuccessful(false)}
          />
        </Portal>
      )}

      {isAddTokensSuccessful && (
        <Portal className="portal_home">
          <AddTokensConfirmationPopup
            onCancel={() => setAddTokensSuccessful(false)}
          />
        </Portal>
      )}

      <h1 className={styles.home_gallery_title}>
        Explore <span>AI Characters</span>
      </h1>
      <div className={styles.home_galery_nav}>
        <HeaderNav />
      </div>
      <div className={styles.home_gallery_wrapper}>
        {models.map((model: IModel) => (
          <GalleryItemFactory
            model={model}
            variant="full_meta"
            key={model.id}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeGallery;
