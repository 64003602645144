import styles from "./styles_ethnicity.module.css";

import Caucasian from "@assets/constructor/ethnicity/caucasian.png";
import Latina from "@assets/constructor/ethnicity/latina.png";
import Asian from "@assets/constructor/ethnicity/asian.png";
import Arab from "@assets/constructor/ethnicity/arab.png";
import Afro from "@assets/constructor/ethnicity/afro.png";

import EyeBrown from "@assets/constructor/ethnicity/eye_brown.png";
import EyeBlue from "@assets/constructor/ethnicity/eye_blue.png";
import EyeGreen from "@assets/constructor/ethnicity/eye_green.png";

interface IProps {
  age: string;
  eye: string;
  ethnicity: string;
  ethnicityChoose: (value: string) => void;
  ageChoose: (value: string) => void;
  eyeChoose: (value: string) => void;
}

interface IPersonality {
  title: string;
  value: string;
  icon?: string;
}

const ETHNICITY: IPersonality[] = [
  {
    title: "Caucasian",
    value: "caucasian",
    icon: Caucasian,
  },
  {
    title: "Latina",
    value: "latina",
    icon: Latina,
  },
  {
    title: "Asian",
    value: "asian",
    icon: Asian,
  },
  {
    title: "Arab",
    value: "arab",
    icon: Arab,
  },
  {
    title: "Black/Afro",
    value: "blackAfro",
    icon: Afro,
  },
];

const AGE: Pick<IPersonality, "title" | "value">[] = [
  {
    title: "Teen(18+)",
    value: "teen(18+)",
  },
  {
    title: "20s",
    value: "20s",
  },
  {
    title: "30s",
    value: "30s",
  },
  {
    title: "40-45",
    value: "40-45",
  },
];

const EYE: IPersonality[] = [
  {
    title: "Brown",
    value: "brown",
    icon: EyeBrown,
  },
  {
    title: "Blue",
    value: "blue",
    icon: EyeBlue,
  },
  {
    title: "Green",
    value: "green",
    icon: EyeGreen,
  },
];

const ChooseEthnicity = ({
  ethnicity,
  age,
  eye,
  ethnicityChoose,
  ageChoose,
  eyeChoose,
}: IProps) => {
  return (
    <div>
      <h4 className={styles.ethnicity_title}>Choose Ethnicity</h4>
      <div className={styles.ethnicity_container}>
        {ETHNICITY.map((item, index) => (
          <EthnicityItem
            key={index}
            item={item}
            ethnicity={ethnicity}
            ethnicityChoose={ethnicityChoose}
          />
        ))}
      </div>

      <h4 className={styles.ethnicity_title}>Choose Age</h4>
      <div className={styles.ai_age_container}>
        {AGE.map((item, index) => (
          <AgeItemProps
            key={index}
            item={item}
            age={age}
            ageChoose={ageChoose}
          />
        ))}
      </div>

      <h4 className={styles.ethnicity_title}>Choose Eyes Color</h4>
      <div className={styles.eyes_container}>
        {EYE.map((item, index) => (
          <EyeItem key={index} item={item} eye={eye} eyeChoose={eyeChoose} />
        ))}

        {/* <div
          className={`${styles.eye_item} ${
            eye === "blue" ? styles.eye_active : ""
          }`}
          onClick={() => eyeChoose("blue")}
        >
          <div className={styles.eye_wrapper}>
            <img src={EyeBlue} alt="blue" />
          </div>
          <div className={styles.eye_color}>Blue</div>
        </div>

        <div
          className={`${styles.eye_item} ${
            eye === "green" ? styles.eye_active : ""
          }`}
          onClick={() => eyeChoose("green")}
        >
          <div className={styles.eye_wrapper}>
            <img src={EyeGreen} alt="green" />
          </div>
          <div className={styles.eye_color}>Green</div>
        </div> */}
      </div>
    </div>
  );
};

interface IEthnicityProps {
  item: IPersonality;
  ethnicity: string;
  ethnicityChoose: (data: string) => void;
}

const EthnicityItem = ({
  item,
  ethnicity,
  ethnicityChoose,
}: IEthnicityProps) => {
  return (
    <div
      className={`${styles.ethnicity_item} ${
        ethnicity === item.value && styles.ethnicity_active
      }`}
      onClick={() => ethnicityChoose(item.value)}
    >
      <img src={item.icon} alt="Caucasian" />
      <div className={styles.ethnicity_badge}>{item.title}</div>
    </div>
  );
};

interface IAgEProps {
  item: IPersonality;
  age: string;
  ageChoose: (data: string) => void;
}

const AgeItemProps = ({ item, age, ageChoose }: IAgEProps) => {
  return (
    <div
      className={`${styles.ai_age_item} ${
        age === item.value ? styles.ai_age_item_active : ""
      }`}
      onClick={() => ageChoose(item.value)}
    >
      {item.title}
    </div>
  );
};

interface IEyeProps {
  item: IPersonality;
  eye: string;
  eyeChoose: (data: string) => void;
}

const EyeItem = ({ item, eye, eyeChoose }: IEyeProps) => {
  return (
    <div
      className={`${styles.eye_item} ${
        eye === item.value ? styles.eye_active : ""
      }`}
      onClick={() => eyeChoose(item.value)}
    >
      <div className={styles.eye_wrapper}>
        <img src={item.icon} alt={item.title} />
      </div>
      <div className={styles.eye_color}>{item.title}</div>
    </div>
  );
};

export default ChooseEthnicity;
