import styles from "./styles.module.css";
import { ChatIcon } from "@shared/ui/ChatIcon";
import { IModel, ROUTES } from "@shared/interfaces";
import { LinkFactory } from "@shared/ui/Link";
import { useState } from "react";
import ModelCardFullMetaSkeleton from "./ModelCardFullMetaSkeleton";

interface IProps {
  model: IModel;
}

const ModelCardFullMeta: React.FC<IProps> = ({ model }: IProps) => {
  const [isImgLoaded, setImgLoaded] = useState<boolean>(true);

  const onLoad = () => {
    setImgLoaded(false);
  };

  return (
    <div
      className={`${styles.model_container} ${
        isImgLoaded ? styles.img_loded : ""
      }`}
    >
      <div className={styles.model_card_skeleton}>
        <ModelCardFullMetaSkeleton />
      </div>
      <LinkFactory
        label=""
        path={`${ROUTES.CHAT}/${model.id}`}
        className={[styles.link_typeof_div]}
      >
        <div className={styles.model_wrapper}>
          <img src={model.photo} alt={`model-${model.id}`} onLoad={onLoad} />
        </div>
        <div className={styles.model_info}>
          <div className={styles.model_info_main}>
            <h4>
              {model.name}
              <span>, {model.age} years</span>
            </h4>
          </div>
          <h6 className={styles.model_personality}>{model.description}</h6>
        </div>
        <div className={styles.model_chat_icon}>
          <ChatIcon />
        </div>
      </LinkFactory>
    </div>
  );
};

export default ModelCardFullMeta;
