import styles from "./styles.module.css";
import { Aside } from "@widgets/aside";
import { FooterNav } from "@widgets/footer_nav";
import { Header } from "@widgets/header";
import { useState } from "react";

export interface LayoutProps {
  children: React.ReactNode;
}

const AsideStructure = ({ children }: LayoutProps) => {
  const [showAside, setShowAside] = useState(false);
  return (
    <>
      <Aside showAside={showAside} setShowAside={setShowAside} />
      <div className={styles.aside__children}>
        <Header setShowAside={setShowAside} />
        {children}
      </div>
      <FooterNav />
    </>
  );
};

export default AsideStructure;
