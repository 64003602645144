import styles from "./styles.module.css";
import { LinkFactory } from "@shared/ui/Link";
import { ROUTES } from "@shared/interfaces";
import CloseIcon from "@assets/close.svg";
import UpdatePlanImg from "@assets/update-plan.jpg";
import * as amplitude from "@amplitude/analytics-browser";
import { useSearchParams } from "react-router-dom";

interface IProps {
  onClose: () => void;
}

const UpdatePlan = ({ onClose }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const onClosePopup = () => {
    onClose();
  };

  return (
    <div className={styles.update_plan_base}>
      <div className={styles.update_plan_container}>
        <div className={styles.update_plan_bg}>
          <img src={UpdatePlanImg} alt="update_paln" />
        </div>
        <div className={styles.update_plan_content}>
          <img
            src={CloseIcon}
            className={styles.update_plan_close_button}
            alt="close"
            onClick={onClosePopup}
          />
          <h2>Upgrade To Unlock</h2>
          <span className={styles.update_plan_suptitle}>
            Unlimited Messages
          </span>
          <ul>
            <li>Unlimited Text Messages</li>
            <li>Generate images</li>
            <li>Get 100 FREE Tokens / Month</li>
            <li>And More...</li>
          </ul>

          <div className={styles.update_plan_link}>
            <LinkFactory
              label="Upgrade to Premium"
              type="danger"
              path={
                ROUTES.PLANS +
                "?parent=Premium Purchase Pop-Up&source=Premium Purchase Pop-Up"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePlan;
